header {
    text-align: center;
}

.logo {
    max-width: 100%;
    /* Ensure the logo doesn't exceed the width of its container */
    height: 100px;
    /* Maintain aspect ratio */
    margin-top: 20px;
    /* Adjust the margin to position the logo */
}

.body {
    border-top: 1px solid #eef1f5;
    border-bottom: 1px solid #eef1f5;
    /* background-color: #eef1f5; */
    min-height: 837px;
}

.custom-card-bg {
    background-color: #f6f6f6;
}



@media screen and (max-width: 480px) {
    .logo {
        max-width: 60%;
        height: 60px;
    }
}


/* .multi-shipment {
    min-height: 100vh;
    background-color: #f6f6f6;
} */

.multi-shipment p,
.multi-shipment h1,
.multi-shipment h2,
.multi-shipment h3,
.multi-shipment h4,
.multi-shipment h5,
.multi-shipment h6,
.multi-shipment span,
.multi-shipment label {
    margin-bottom: 0;
}

.multi-shipment .track-shipment-list .shipment-card .shipment-info-container {
    position: relative;
    z-index: 1;
    margin-bottom: 58px;
}

.multi-shipment .track-shipment-list .shipment-card .shipment-info-container .shipment-updates {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    min-width: 331px;
    text-align: left;
    top: -15px;
}

.multi-shipment .shipment-card .shipment-track-points .title.waybill-title {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    min-width: 331px;
    z-index: 2;
}

.shy-section .multi-shipment {
    min-height: 0;
}

.shy-section:first-of-type .multi-shipment .multi-shipment-container {
    padding-top: 57px;
    padding-bottom: 8px;
}

.shy-section:last-of-type .multi-shipment .multi-shipment-container {
    padding-top: 0px;
    padding-bottom: 90px;
}

.shy-section:not(:last-of-type, :first-of-type) .multi-shipment .multi-shipment-container {
    padding-top: 0px;
    padding-bottom: 8px;
}

.multi-shipment a:hover {
    text-decoration: none;
}

.multi-shipment a:focus {
    outline: none;
}

.multi-shipment .multi-shipment-container {
    padding: 57px 260px 90px;
}

.multi-shipment .track-shipment-card {
    background: #ffffff;
    box-shadow: 0px 6px 16px #e8e8e8b5;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 80px 42px 80px 61px;
    margin-bottom: 21px;
}

.multi-shipment .track-shipment-card .form-group {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 14px;
}

.multi-shipment .track-shipment-card .form-control {
    flex: 0 0 calc(100% - 147px);
    max-width: calc(100% - 147px);
    border-radius: 0;
    width: 100%;
    padding-left: 39.5px;
    padding-bottom: 21px;
    padding-right: 10px;
    font-size: 16px;
    line-height: 20px;
    padding-top: 7px;
    color: #6d7278;
    border: 0;
    border-bottom: 1px solid #6d727880;
    background: url("./ic-barcode-primary.svg") no-repeat;
    background-position: left top;
    background-size: 24px 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multi-shipment .track-shipment-card .form-control:focus {
    box-shadow: none;
}

.multi-shipment .track-shipment-card .form-control::-webkit-input-placeholder {
    color: #6d727880;
}

.multi-shipment .track-shipment-card .form-control::-moz-placeholder {
    color: #6d727880;
}

.multi-shipment .track-shipment-card .form-control:-ms-input-placeholder {
    color: #6d727880;
}

.multi-shipment .track-shipment-card .form-control:-moz-placeholder {
    color: #6d727880;
}

.multi-shipment .track-shipment-card-text {
    color: #6d7278;
    font-size: 14px;
    line-height: 17px;
}

.multi-shipment .track-shipment-card-text a {
    color: #0c3d3c;
    font-weight: 500;
    display: inline-block;
}

.multi-shipment .track-shipment-card .form-group .btn-primary-red {
    background-color: #0c3d3c;
    color: #ebc278;
    border-radius: 30px;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    min-width: 114px;
    display: inline-block;
    text-align: center;
    border: 0;
    flex: 0 0 114px;
    margin-left: 33px;
}

.multi-shipment .track-shipment-card .form-group .btn-primary-red:hover,
.multi-shipment .track-shipment-card .form-group .btn-primary-red:focus,
.multi-shipment .track-shipment-card .form-group .btn-primary-red:active {
    background-color: #0c3d3c;
    color: #ebc278;
}

.multi-shipment .track-shipment-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.multi-shipment .shipment-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    padding: 40px 40px 35px;
    cursor:default;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
}


.multi-shipment .shipment-card .shipment-info-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 28px;
    width: 100%;
}

.multi-shipment .shipment-card .shipment-info h6 {
    color: #d49a46;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 1px;
    padding-bottom: 0;
    text-align: left;
}

.multi-shipment .shipment-card .shipment-info .shipment-num {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 4px;
}

.multi-shipment .shipment-card .shipment-info .shipment-num h5 {
    padding-bottom: 0;
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    color: #404040;
    padding-bottom: 0;
}

.multi-shipment .shipment-card .shipment-info .shipment-num img {
    display: block;
    height: 24px;
    width: 24px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-updates {
    max-width: 331px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-updates .title {
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
    color: #353535;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-updates .shipment-update-descp {
    font-size: 13px;
    line-height: 17px;
    color: #353535;
    margin-bottom: 0;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-updates .shipment-update-datetime {
    font-size: 11px;
    line-height: 17px;
    color: #aaaaaa;
}

.multi-shipment .shipment-card .shipment-track-points {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: #404040;
    column-gap: 4px;
    margin-bottom: 1px;
    width: 100%;
}

.multi-shipment .shipment-card .shipment-track-points .track {
    flex: auto;
    border-top: 2px dashed #efefef;
}

.multi-shipment .shipment-card .shipment-track-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* margin-bottom: 8px; */
}

.multi-shipment .shipment-card .shipment-track-info .inbetween-info {
    flex: 1 0 0%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.multi-shipment .shipment-card .shipment-track-info .orgin-info {
    width: 290px;
    text-align: left;
}

.multi-shipment .shipment-card .shipment-track-info .country {
    font-size: 12px;
    line-height: 16px;
    color: #404040;
    font-weight: 700;
    display: block;
}

.multi-shipment .shipment-card .shipment-track-info .city {
    font-size: 12px;
    line-height: 16px;
    color: #0c3d3c;
    display: block;
}

.multi-shipment .shipment-card .shipment-track-info .waybill {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: #0c3d3c;
    display: block;
    padding-right: 6px;
}

.multi-shipment .shipment-card .shipment-track-info .waybill.waybill-number {
    align-items: flex-start;
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    min-width: 331px;
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-track-info .waybill .shy-cp-link {
    font-weight: 700;
    width: 300px;
}

.multi-shipment .shipment-card .shipment-track-info .pickup-location {
    font-size: 11px;
    line-height: 17px;
    color: #aaaaaa;
}

.multi-shipment .shipment-card .shipment-track-points .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    color: #353535;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .inbetween-info {
    max-width: 122px;
    text-align: center;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info span.country {
    font-size: 12px;
    line-height: 16px;
    color: #404040;
    font-weight: 700;
    display: block;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info span.city {
    font-size: 12px;
    line-height: 16px;
    color: #0c3d3c;
    display: block;
    font-weight: 400;
}

.multi-shipment .shipment-card .shipment-track-info .dest-info {
    text-align: right;
}

.multi-shipment .shipment-card .shipment-progess-container {
    display: flex;
    width: 100%;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    z-index: 1;
    position: relative;
    flex: 1 0 0%;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start {
    flex: 0;
    min-width: 122px;
    max-width: 122px;
    align-items: flex-start;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end {
    flex: 0;
    align-items: flex-end;
    min-width: 122px;
    max-width: 122px;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point::after {
    content: " ";
    position: absolute;
    background-color: #cbcccd;
    height: 3px;
    width: 50%;
    left: 50%;
    bottom: 41.5px;
    z-index: 0;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start::after {
    width: 80%;
    left: 20%;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start::before,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end::after {
    display: none;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point::before {
    content: " ";
    position: absolute;
    background-color: #cbcccd;
    height: 3px;
    width: 50%;
    left: 0;
    bottom: 41.5px;
    z-index: 0;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end::before {
    width: 80%;
}

.multi-shipment .shipment-card .shipment-progess-container:not(.created) .shipment-progess-point.done::after,
.multi-shipment .shipment-card .shipment-progess-container:not(.created) .shipment-progess-point.done::before,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current::before {
    background-color: #0c3d3c;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .track-check-info {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    margin-top: auto;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .track-check {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 41px;
    width: 41px;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    justify-content: center;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start .track-check::after,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check::after {
    position: absolute;
    content: " ";
    height: 32.5px;
    width: 32.5px;
    border-radius: 50%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start .track-check::before,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check::before {
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start .track-check::after {
    background-color: #0c3d3c80;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start .track-check::before {
    background-color: #0c3d3c20;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check::after {
    background-color: #a4a4a4;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check::before {
    background-color: #ebebeb;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start .track-check div,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check div {
    display: block;
    background-size: 100% 100%;
    position: relative;
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end .track-check div {
    background: url("./ic-check-secondary-dark.svg") no-repeat;
    mix-blend-mode: luminosity;
    height: 17px;
    width: 17px;
}

.multi-shipment .shipment-card .shipment-progess-container:not(.created) .shipment-progess-point.start.done .track-check div,
.multi-shipment .shipment-card .shipment-progess-container:not(.created) .shipment-progess-point.done .track-check div {
    background: url("./ic-check-primary-small.svg") no-repeat;
    height: 17px;
    width: 17px;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current:not(.end) .track-check div {
    height: 31px;
    width: 31px;
    background-position: top;
    position: relative;
    z-index: 10;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current .track-check div::before {
    content: " ";
    position: absolute;
    background: url("./stepper-box.png") no-repeat;
    height: 31px;
    width: 31px;
    background-position: top;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current .track-check div::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    background-color: #0c3d3c;
    border-radius: 50%;
    animation: pulse 0.8s ease infinite;
}

@keyframes pulse {
    0% {
        height: 27px;
        width: 27px;
        opacity: 1;
    }

    100% {
        height: 29px;
        width: 29px;
        opacity: 0;
    }
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end.current .track-check div::before {
    background: url("./ic-check-success.svg") no-repeat;
    height: 17px;
    width: 17px;
    background-position: center;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end.current .track-check div {
    background: none;
    mix-blend-mode: normal;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current.end .track-check div::after {
    background-color: #d49a46;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current.end .track-check::before,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.current.end .track-check::after {
    background-color: #0c3d3c20;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point span {
    font-size: 11px;
    line-height: 14px;
    color: #bcbcbc;
}

.multi-shipment .shipment-card .shipment-progess-container.created .shipment-progess-point.current span,
.multi-shipment .shipment-card .shipment-progess-container.created .shipment-progess-point.current.done span,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.done span {
    color: #000000;
}

.multi-shipment .shipment-card .shipment-progess-container:not(.created) .shipment-progess-point.current span:not(.country, .city) {
    color: #000000;
    font-weight: 700;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed {
    opacity: 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #a8a8a880;
    border: 1px solid #f8f8f8;
    padding: 16px 10px 10px;
    border-radius: 10px;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #353535;
    min-width: 184px;
    max-width: 184px;
    top: 0;
    transition: all 0.3s ease;
    visibility: hidden;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed .arrow {
    width: 50px;
    height: 25px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed .arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 0;
    left: 50%;
    box-shadow: 0px 3px 6px #a8a8a880;
    border: 1px solid #f8f8f8;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked {
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked>span {
    color: #353535;
    font-weight: 700;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked .tooltip-departed {
    opacity: 1;
    transition: all 0.3s ease;
    visibility: visible;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed .title {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: #353535;
}

/* -------------------------------------------------- */
.multi-shipment .track-shipment-details .track-shipment-breadcrumbs {
    margin-bottom: 23px;
    margin-top: -11px;
}

.multi-shipment .track-shipment-details .track-shipment-breadcrumbs ul {
    display: flex;
    align-items: center;
    column-gap: 4px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.multi-shipment .track-shipment-details .track-shipment-breadcrumbs ul li {
    font-size: 14px;
    line-height: 18px;
    color: #484848;
}

.multi-shipment .track-shipment-details .track-shipment-breadcrumbs ul li.active {
    color: #e1251b;
}

.multi-shipment .shipment-details-card {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 32px 32px 34px;
}

.multi-shipment .track-shipment-details .shipment-details-card {
    margin-top: 16px;
    margin-bottom: 35px;
}

.multi-shipment .track-shipment-details .shipment-details-card .shipment-details-card-title {
    font-size: 12px;
    line-height: 16px;
    color: #898989;
    margin-bottom: 8px;
}

.multi-shipment .track-shipment-details .shipment-details-card .shipment-details-row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    margin: 0 -15px;
}

.multi-shipment .track-shipment-details .shipment-details-card .shipment-details-col {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
}

.multi-shipment .shipment-details-info {
    display: flex;
    column-gap: 16px;
    align-items: center;
}

.multi-shipment .shipment-details-info .icon {
    display: flex;
    align-items: center;
    height: 32px;
    min-width: 32px;
}

.multi-shipment .shipment-details-info .icon img {
    display: block;
}

.multi-shipment .shipment-details-info .shipment-details-title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 4px;
    color: #404040;
    display: flex;
    align-items: center;
}

.multi-shipment .shipment-details-info .shipment-details-data {
    font-size: 12px;
    line-height: 16px;
    color: #0c3d3c;
    font-weight: 700;
    display: block;
}

.multi-shipment .shipment-details-info .shipment-details-data .cross {
    color: #404040;
}

.multi-shipment .shipment-details-info .shipment-details-data sup {
    font-size: 8px;
    line-height: 16px;
}

.multi-shipment .shipment-login-card {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 19px 26px 20px;
    display: flex;
    column-gap: 17px;
    align-items: center;
}

.multi-shipment .track-shipment-details .shipment-login-card {
    margin-bottom: 8px;
}

.multi-shipment .shipment-login-card img {
    display: block;
    height: 45px;
    width: 45px;
}

.multi-shipment .shipment-login-card .shipment-login-card-title {
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: #404040;
    margin-bottom: 8px;
}

.multi-shipment .shipment-login-card .shipment-login-card-text {
    font-size: 12px;
    line-height: 14px;
    color: #393939;
}

.multi-shipment .shipment-history-track {
    background-color: #f7f7f7;
    box-shadow: 0px 3px 6px #00000029;
    padding: 28px 22px 23px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.multi-shipment .shipment-history-track h1 {
    font-size: 28px;
    line-height: 38px;
    color: #000000;
}

.multi-shipment .shipment-history-track .btn-text-primary {
    font-size: 14px;
    line-height: 22px;
    color: #0c3d3c;
    display: flex;
    align-items: center;
    font-weight: 500;
}

.multi-shipment .shipment-history-track .btn-text-primary img {
    display: block;
    height: 8px;
    transition: all 0.2s ease;
    margin-left: 18px;
}

.multi-shipment .shipment-history-track .hide-history {
    display: none;
}

.multi-shipment .shipment-history-track.collapsed .show-history {
    display: none;
}

.multi-shipment .shipment-history-track.collapsed .hide-history {
    display: block;
}

.multi-shipment .shipment-history-track.collapsed .btn-text-primary img {
    transform: rotate(180deg);
}

.multi-shipment .history-details-card {
    background-color: #fff;
    padding-top: 24px;
    margin-top: 8px;
}

.multi-shipment .history-details-card table {
    width: 100%;
}

.multi-shipment .history-details-card table thead th {
    background-color: #9293931a;
    padding: 19.5px 14px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #404040;
}

.multi-shipment .history-details-card table tbody tr th,
.multi-shipment .history-details-card table tbody tr td {
    padding: 15px 14px 4px;
    vertical-align: middle;
    color: #6a6a6a;
    font-size: 14px;
    line-height: 18px;
}

.multi-shipment .shipment-details-info .shipment-details-title .total {
    padding-left: 4px;
    border-left: 1px solid #f7f7f7;
    font-weight: 700;
}

.multi-shipment .history-details-card table tbody tr .activity {
    vertical-align: baseline;
}

.multi-shipment .history-details-card table tbody tr .track-check {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 6px;
    position: relative;
    z-index: 1;
}

.multi-shipment .history-details-card table tbody tr .track-check::after,
.multi-shipment .history-details-card table tbody tr .track-check::before {
    content: " ";
    z-index: 0;
    position: absolute;
    height: 50%;
    width: 3px;
    left: 25px;
    background-color: #b3b3b3;
}

.multi-shipment .history-details-card table tbody tr .track-check::after {
    top: 50%;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check::before {
    display: none;
}

.multi-shipment .history-details-card table tbody tr .track-check::before {
    top: 0;
}

.multi-shipment .history-details-card table tbody tr:last-of-type .track-check::after {
    display: none;
}

.multi-shipment .history-details-card .collected tbody tr:last-of-type .track-check::before,
.multi-shipment .history-details-card .collected tbody tr:nth-last-of-type(2) .track-check::after {
    background-color: #0c3d3c;
}

.multi-shipment .history-details-card .delivered tbody tr .track-check::before,
.multi-shipment .history-details-card .delivered tbody tr .track-check::after {
    background-color: #0c3d3c;
}

.multi-shipment .history-details-card table tbody tr:not(:first-of-type, :last-of-type) .track-check .check-radio {
    z-index: 1;
    position: relative;
    height: 41px;
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multi-shipment .history-details-card table tbody tr:not(:first-of-type, :last-of-type) .track-check .check-radio div {
    display: block;
    height: 20px;
    width: 20px;
    background-size: 100% 100%;
    /* background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-check-secondary-large.svg") no-repeat; */
}

/* .multi-shipment
.history-details-card
.collected
tbody
tr:not(:first-of-type, :last-of-type)
.track-check
.check-radio
div,
.multi-shipment
.history-details-card
.delivered
tbody
tr:not(:first-of-type, :last-of-type)
.track-check
.check-radio
div {
    background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-check-primary-large.svg") no-repeat;
} */

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio,
.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio {
    z-index: 1;
    position: relative;
    height: 41px;
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio::after,
.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio::after {
    position: absolute;
    content: " ";
    height: 32.5px;
    width: 32.5px;
    border-radius: 50%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio::after {
    background-color: #b3b3b31f;
}

.multi-shipment .history-details-card .delivered tbody tr:first-of-type .track-check .check-radio::after {
    background-color: #caeecb;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio::before,
.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio::before {
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio::before {
    background-color: #b3b3b320;
}

.multi-shipment .history-details-card .delivered tbody tr:first-of-type .track-check .check-radio::before {
    background-color: #0c3d3c20;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio div,
.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio div {
    display: block;
    height: 17px;
    width: 17px;
    background-size: 100% 100%;
    position: relative;
    z-index: 2;
}

.multi-shipment .history-details-card table tbody tr:first-of-type .track-check .check-radio div {
    background: url("./ic-check-success.svg") no-repeat;
}

/* .multi-shipment
.history-details-card
.collected
tbody
tr:first-of-type
.track-check
.check-radio
div {
    background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-check-secondary-small.svg") no-repeat;
} */

.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio::after {
    background-color: #0c3d3c80;
}

.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio::before {
    background-color: #0c3d3c20;
}

.multi-shipment .history-details-card table tbody tr:last-of-type .track-check .check-radio div {
    background: url("./ic-check-primary-small.svg") no-repeat;
}

.multi-shipment .history-details-card table tbody tr .date-time {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.multi-shipment .history-details-card table tbody tr .date-time .date {
    line-height: 22px;
}

.multi-shipment .history-details-card table tbody tr .date-time .time {
    font-size: 10px;
    line-height: 13px;
}

.multi-shipment .history-details-card table tbody tr .date-time .not-avl {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.multi-shipment .history-details-card table tbody tr .date-time .not-avl span {
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #d4d4d4;
}

.multi-shipment .history-details-card table tbody tr .addr {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.multi-shipment .history-details-card table tbody tr .addr .city {
    line-height: 21px;
}

.multi-shipment .history-details-card table tbody tr .addr .country {
    line-height: 21px;
    font-weight: 700;
}

.multi-shipment .history-details-card table tbody tr:nth-of-type(2n) th,
.multi-shipment .history-details-card table tbody tr:nth-of-type(2n) td {
    background-color: #9293931a;
}

.multi-shipment .history-details-card table tbody tr:nth-of-type(2n + 1) th,
.multi-shipment .history-details-card table tbody tr:nth-of-type(2n + 1) td {
    background-color: #fff;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start::before,
.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end::after {
    display: none;
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num {
    min-width: 22px;
    max-width: 22px;
    margin-left: 24px;
    display: flex;
    align-items: center;
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-track .slick-slide {
    padding-left: 8px;
    font-weight: 700;
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow {
    position: absolute;
    height: 10px;
    width: 10px;
    z-index: 1;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow.prev {
    left: -10px;
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow.next {
    right: -35px;
}

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow .arrow-icon {
    height: 10px;
    width: 10px;
    background-size: 100% 100%;
    display: block;
}

/* .multi-shipment
        .shipment-details-info
        .shipment-details-title
        .type-num
        .slick-arrow.prev[aria-disabled="true"]
        .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-prev-inactive-small.svg") no-repeat;
        } */

.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow.prev[aria-disabled="true"],
.multi-shipment .shipment-details-info .shipment-details-title .type-num .slick-arrow.next[aria-disabled="true"] {
    cursor: default;
}

/* .multi-shipment
        .shipment-details-info
        .shipment-details-title
        .type-num
        .slick-arrow.prev[aria-disabled="false"]
        .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-prev-active-small.svg") no-repeat;
        } */

/* .multi-shipment
        .shipment-details-info
        .shipment-details-title
        .type-num
        .slick-arrow.next[aria-disabled="false"]
        .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-next-active-small.svg") no-repeat;
        } */

/* .multi-shipment
        .shipment-details-info
        .shipment-details-title
        .type-num
        .slick-arrow.next[aria-disabled="true"]
        .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-next-inactive-small.svg") no-repeat;
        } */

.multi-shipment .shipment-details-info>div {
    max-width: calc(100% - 40px);
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num {
    width: 154px;
    margin-left: 20px;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .plate {
    width: 100%;
    /* background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/mask-plate.png") no-repeat; */
    background-size: 100% 100%;
    padding-left: 5px;
    padding-right: 5px;
    height: 31px;
    min-width: 59px;
    white-space: nowrap;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 13px;
    color: #3b3b3b;
    font-weight: 700;
    margin: 0 auto;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow {
    position: absolute;
    height: 10px;
    width: 10px;
    z-index: 1;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.prev {
    left: -20px;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.next {
    right: -20px;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow .arrow-icon {
    height: 10px;
    width: 10px;
    background-size: 100% 100%;
    display: block;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.prev[aria-disabled="true"]
/* .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-prev-inactive-small.svg") no-repeat;
        } */

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.prev[aria-disabled="true"],
.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.next[aria-disabled="true"] {
    cursor: default;
}

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.prev[aria-disabled="false"]
/* .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-prev-active-small.svg") no-repeat;
        } */

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.next[aria-disabled="false"]
/* .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-next-active-small.svg") no-repeat;
        } */

.multi-shipment .shipment-details-info .shipment-details-data .plate-num .slick-arrow.next[aria-disabled="true"]
/* .arrow-icon {
            background: url("/Sitefinity/WebsiteTemplates/aramex/App_Themes/aramex/Images/ic-next-inactive-small.svg") no-repeat;
        } */

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked {
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked>span {
    color: #353535;
    font-weight: 700;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.clicked .tooltip-departed {
    opacity: 1;
    transition: all 0.3s ease;
}

.multi-shipment .track-shipment-details .shipment-track-map {
    width: 100%;
    height: 308px;
    margin-top: 35px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-actions {
    display: flex;
    align-items: center;
    column-gap: 38px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 0;
    border: 0;
    background-color: transparent;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn:focus {
    box-shadow: none;
    outline: none;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn:active {
    box-shadow: none;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn img {
    display: block;
    height: 32px;
    width: 32px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn {
    display: flex;
    align-items: center;
    column-gap: 12px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 0;
    border: 0;
    background-color: transparent;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn:focus {
    box-shadow: none;
    outline: none;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn:active {
    box-shadow: none;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn img {
    display: block;
    height: 32px;
    width: 32px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify {
    position: relative;
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify {
    opacity: 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px 24px 35px;
    min-width: 331px;
    top: 41px;
    transition: all 0.3s ease;
    left: -93px;
    visibility: hidden;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify.clicked .tooltip-notify {
    opacity: 1;
    visibility: visible;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
    color: #000000;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .arrow {
    width: 50px;
    height: 18px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
    box-shadow: 0px 3px 6px #a8a8a880;
    border: 1px solid #f8f8f8;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .form-group {
    position: relative;
    z-index: 1;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .form-group label {
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #6d7278;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .form-group .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #6d7278;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #353535;
    padding: 5px 0;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn-submit {
    background-color: #0c3d3c;
    color: #ffffff;
    border-radius: 0;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    display: inline-block;
    text-align: center;
    border: 0;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn-submit:hover,
.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn-submit:focus,
.multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn-submit:active {
    background-color: #0c3d3c;
    color: #ffffff;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support {
    position: relative;
    z-index: 2;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support {
    opacity: 0;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px 24px 35px;
    min-width: 331px;
    top: 41px;
    transition: all 0.3s ease;
    left: -93px;
    visibility: hidden;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly {
    padding: 20px 24px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly .support-update {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly label {
    font-size: 12px;
    line-height: 14px;
    display: block;
    color: #6d7278;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly .support-update .support-timestamp {
    font-size: 12px;
    line-height: 14px;
    color: #cccccc;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly .support-email-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #353535;
    margin-bottom: 6px;
    display: block;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly .support-comment {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #353535;
    margin-top: 2px;
    display: block;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly {
    padding: 20px 24px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly .notify-update {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly label {
    font-size: 12px;
    line-height: 14px;
    display: block;
    color: #6d7278;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly .notify-update .notify-timestamp {
    font-size: 12px;
    line-height: 14px;
    color: #cccccc;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly .notify-email-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #353535;
    margin-bottom: 6px;
    display: block;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly .notify-comment {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #353535;
    margin-top: 2px;
    display: block;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support.clicked .tooltip-support {
    opacity: 1;
    visibility: visible;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .title {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
    color: #000000;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .arrow {
    width: 50px;
    height: 18px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    top: 100%;
    left: 50%;
    box-shadow: 0px 3px 6px #a8a8a880;
    border: 1px solid #f8f8f8;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group {
    position: relative;
    z-index: 1;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group label {
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #6d7278;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #6d7278;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #353535;
    padding: 5px 0;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control::-webkit-input-placeholder {
    color: #3535354a;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control::-moz-placeholder {
    color: #3535354a;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control:-ms-input-placeholder {
    color: #3535354a;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control:-moz-placeholder {
    color: #3535354a;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn-submit {
    background-color: #0c3d3c;
    color: #ffffff;
    border-radius: 0;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
    display: inline-block;
    text-align: center;
    border: 0;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn-submit:disabled {
    background-color: #b4b3b3;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn-submit:hover,
.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn-submit:focus,
.multi-shipment .shipment-card .shipment-info-container .shipment-support .btn-submit:active {
    background-color: #0c3d3c;
    color: #ffffff;
}

.multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support .form-group .form-control:focus,
.multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify .form-group .form-control:focus {
    box-shadow: none;
}

.multi-shipment .shipment-rating-card {
    background: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 13px 15px 28px;
    display: flex;
    column-gap: 16px;
    margin-bottom: 8px;
}

.multi-shipment .shipment-rating-card .rating-icon {
    display: block;
    height: 46px;
    width: 46px;
}

.multi-shipment .shipment-rating-card .shipment-rating-info h6 {
    color: #353535;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
    font-weight: 700;
    padding-top: 12px;
}

.multi-shipment .shipment-rating-card .shipment-rating-info p {
    color: #353535;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars {
    margin-bottom: 25px;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul li {
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul li img {
    display: block;
    height: 18px;
    width: 19px;
    transition: all 0.2s ease;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul li img.active {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul li.hover img.active,
.multi-shipment .shipment-rating-card .shipment-rating-info .rating-stars ul li.selected img.active {
    opacity: 1;
}

.multi-shipment .shipment-rating-card .btn-primary-red {
    background-color: #0c3d3c;
    color: #ffffff;
    border-radius: 30px;
    text-decoration: none;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.23px;
    min-width: 80px;
    display: inline-block;
    text-align: center;
    border: 0;
}

.multi-shipment .shipment-rating-card .btn-primary-red:hover,
.multi-shipment .shipment-rating-card .btn-primary-red:focus,
.multi-shipment .shipment-rating-card .btn-primary-red:active {
    background-color: #0c3d3c;
    color: #ffffff;
}

section.track-shipment {
    padding-top: 30px;
}

.DepartedW {
    width: 23.28px !important;
}

.ArrivedW {
    width: 22.73px !important;
}

.multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info span.location-title {
    font-weight: 400 !important;
    color: #000000;
}

/*------------------- Shipment Attachment ---------------- */
.multi-shipment .shipment-attachment-card {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 32px;
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.multi-shipment .shipment-attachment-card-header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #0c3d3c;
}

.multi-shipment .shipment-attachment-card-header .shipment-attachment-heading {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: #000000;
}

.multi-shipment .shipment-attachment-card-header .shipment-attachment-heading .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c3d3c;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
}

.multi-shipment .shipment-attachment-card-header .shipment-attachment-heading .icon img {
    display: block;
    height: 20px;
    width: 20px;
}

.multi-shipment .shipment-attachment-card-header .shipment-attachment-heading .title {
    font-size: 18px;
    line-height: 23px;
    color: #000000;
    font-weight: 500;
}

.multi-shipment .shipment-attachment-card-header .link-show {
    font-size: 14px;
    line-height: 18px;
    color: #0c3d3c;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.multi-shipment .shipment-attachment-card-header .link-show .hide-attachment {
    display: none;
}

.multi-shipment .shipment-attachment-card-header .link-show.collapsed .hide-attachment {
    display: block;
}

.multi-shipment .shipment-attachment-card-header .link-show .show-attachment {
    display: block;
}

.multi-shipment .shipment-attachment-card-header .link-show.collapsed .show-attachment {
    display: none;
}

.multi-shipment .shipment-attachment-card-header .link-show:hover,
.multi-shipment .shipment-attachment-card-header .link-show:focus {
    text-decoration: underline;
}

.multi-shipment .shipment-attachment-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.multi-shipment .shipment-attachment-list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 16px 0 15px;
    cursor: pointer;
}

.multi-shipment .shipment-attachment-list-item:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.multi-shipment .shipment-attachment-list-item:not(:last-of-type) {
    border-bottom: 1px solid #cccccc;
}

.multi-shipment .shipment-attachment-list-item .shipment-attachment-file {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 260px;
}

.multi-shipment .shipment-attachment-list-item .shipment-attachment-file .file {
    font-size: 14px;
    line-height: 18px;
    color: #7f7f7f;
}

.multi-shipment .shipment-attachment-list-item .shipment-attachment-file .title {
    font-size: 18px;
    line-height: 23px;
    color: #000000;
}

.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action {
    display: flex;
    justify-content: center;
}

.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action .btn-download {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0c3d3c;
    color: #ffffff;
    border-radius: 19.5px;
    text-decoration: none;
    padding-left: 24.47px;
    padding-right: 24.47px;
    padding-top: 3.55px;
    padding-bottom: 3.55px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.23px;
    min-width: 167px;
    border: 0;
    column-gap: 8px;
    white-space: nowrap;
}

.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action .btn-download img {
    display: block;
    height: 24px;
    width: 24px;
}

.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action .btn-download:hover,
.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action .btn-download:focus,
.multi-shipment .shipment-attachment-card-content .shipment-attachment-card-action .btn-download:active {
    background-color: #0c3d3c;
    color: #ffffff;
}

.multi-shipment .shipment-attachment-list-item .shipment-attachment-download {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 4px;
    margin-right: -4px;
    border: 0;
    background-color: transparent;
    font-size: 16px;
    line-height: 21px;
    color: #7f7f7f;
    font-weight: 500;
}

.multi-shipment .shipment-attachment-list-item .shipment-attachment-download img {
    display: block;
    height: 24px;
    width: 24px;
}

.multi-shipment .shipment-attachment-list-item:hover .shipment-attachment-download,
.multi-shipment .shipment-attachment-list-item .shipment-attachment-download:hover,
.multi-shipment .shipment-attachment-list-item .shipment-attachment-download:active,
.multi-shipment .shipment-attachment-list-item .shipment-attachment-download:focus {
    color: #0c3d3c;
}



.multi-shipment .shipment-card .shipment-info .shipment-waybills {
    display: none;
}

/* @media (max-width: 1559px) {
    .multi-shipment .multi-shipment-container {
        padding: 57px 210px 90px;
    }
} */

@media (max-width: 1399px) {
    .multi-shipment .multi-shipment-container {
        padding: 57px 132px 90px;
    }

    .multi-shipment .track-shipment-card {
        padding-left: 40px;
        padding-right: 40px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-updates {
        max-width: 290px;
    }
}

@media (max-width: 575px) {
    .multi-shipment .shipment-card .shipment-track-info .orgin-info {
        text-align: right;
    }

    .multi-shipment .shipment-card {
        box-shadow: none;
    }
    .empty-state{
        width: 102% !important;
    }
}

@media (max-width: 1199px) {
    .multi-shipment .multi-shipment-container {
        padding: 57px 40px 90px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-updates {
        max-width: 282px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-actions {
        column-gap: 12px;
    }
}

@media (max-width: 991px) {
    .multi-shipment .track-shipment-list .shipment-card .shipment-info-container .shipment-updates {
        max-width: 286px;
        min-width: 286px;
    }

    .multi-shipment .shipment-card .shipment-track-points .title.waybill-title,
    .multi-shipment .shipment-card .shipment-track-info .waybill.waybill-number {
        max-width: 286px;
        min-width: 286px;
    }

    .multi-shipment .multi-shipment-container {
        padding: 57px 20px 90px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-updates {
        max-width: 286px;
    }

    .multi-shipment .shipment-card {
        padding: 40px 24px 30px;
    }

    .multi-shipment .shipment-card .shipment-stops {
        margin-bottom: 0;
    }

    .multi-shipment .track-shipment-card {
        padding: 60px 24px;
    }

    .multi-shipment .track-shipment-details .shipment-details-card .shipment-details-col {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .multi-shipment .track-shipment-details .shipment-details-card .shipment-details-col.empty-div {
        display: none;
    }

    .multi-shipment .shipment-details-info {
        column-gap: 8px;
    }

    .multi-shipment .shipment-history-track h1 {
        font-size: 24px;
        line-height: 30px;
    }

    .multi-shipment .shipment-details-card,
    .multi-shipment .shipment-attachment-card {
        padding: 30px 24px 28px;
    }

    .multi-shipment .track-shipment-details .shipment-details-card .shipment-details-row {
        row-gap: 32px;
        margin: 0 -15px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-support .btn span,
    .multi-shipment .shipment-card .shipment-info-container .shipment-notify .btn span {
        display: none;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support {
        padding: 20px 20px 30px;
        min-width: 276px;
        top: 49px;
        left: -121px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify {
        padding: 20px 20px 30px;
        min-width: 268px;
        top: 49px;
        left: -120px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly,
    .multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly {
        padding: 20px;
    }
}

@media (max-width: 767px) {
    .multi-shipment .track-shipment-list .shipment-card .shipment-info-container .shipment-updates {
        transform: none;
        position: relative;
        left: 0;
        text-align: left;
    }


    .multi-shipment .track-shipment-list .shipment-card .shipment-info-container {
        margin-bottom: 22px;
    }

    .multi-shipment .multi-shipment-container {
        padding: 48px 16px 74px;
    }

    .multi-shipment .track-shipment-card {
        padding: 48px 16px;
    }

    .multi-shipment .track-shipment-card .form-group .btn-primary-red {
        margin-left: 24px;
    }

    .multi-shipment .track-shipment-card .form-control {
        flex-basis: calc(100% - 138px);
        max-width: calc(100% - 138px);
    }

    .multi-shipment .shipment-card {
        padding: 30px 16px 28px;
        /* padding: 0 16px 28px; */
    }

    .multi-shipment .shipment-card .shipment-info .shipment-num h5 {
        font-size: 16px;
        line-height: 20px;
    }

    .multi-shipment .shipment-card .shipment-info .shipment-num {
        column-gap: 6px;
    }

    .multi-shipment .shipment-card .shipment-info-container {
        flex-wrap: wrap;
        row-gap: 16px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-updates {
        order: 3;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-updates .shipment-update-descp {
        max-width: 386px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-status {
        order: 2;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point span {
        font-size: 10px;
    }

    .multi-shipment .track-shipment-details .shipment-details-card .shipment-details-col {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .multi-shipment .shipment-history-track {
        padding: 20px 12px 20px 16px;
    }

    .multi-shipment .shipment-history-track h1 {
        font-size: 20px;
        line-height: 26px;
    }

    .multi-shipment .shipment-login-card {
        padding: 16px;
    }

    .multi-shipment .shipment-details-card,
    .multi-shipment .shipment-attachment-card {
        padding: 24px 16px 20px;
    }

    .multi-shipment .history-details-card table thead th.col-activity {
        min-width: 286px;
    }

    .multi-shipment .shipment-history-track .btn-text-primary img {
        margin-left: 10px;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point span {
        font-size: 10px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support {
        padding: 20px 16px 24px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify {
        padding: 20px 16px 24px;
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-notify .tooltip-notify.readonly,
    .multi-shipment .shipment-card .shipment-info-container .shipment-support .tooltip-support.readonly {
        padding: 20px 16px;
    }

    .multi-shipment .shipment-attachment-card-header {
        padding-bottom: 16px;
    }

    .multi-shipment .shipment-card .shipment-info .shipment-waybills {
        display: block;
        margin-top: 15px;
    }

    .multi-shipment .shipment-card .shipment-info .shipment-waybills .waybill-number {
        color: #0c3d3c;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 10px;
        max-width: 220px;
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 0;
    }

    .multi-shipment .shipment-card .shipment-info .shipment-waybills .waybill-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 13px;
        color: #353535;
    }

    .multi-shipment .shipment-card .shipment-track-points .title.waybill-title,
    .multi-shipment .shipment-card .shipment-track-info .waybill .shy-cp-link {
        display: none;
    }
}


@media (max-width: 667px) {
    .sf_colsIn.shy-container {
        display: block;
    }
}

@media (max-width: 575px) {
    .multi-shipment .track-shipment-card .form-group {
        margin-bottom: 8px;
    }

    .multi-shipment .track-shipment-card .form-group .btn-primary-red {
        flex-basis: 88px;
        min-width: 88px;
        margin-left: 16px;
    }

    .multi-shipment .shipment-card .shipment-track-points.custom-points {
        display: none;
    }

    .multi-shipment .shipment-card .location-info-wrap {
        display: flex;
        flex-direction: column-reverse;
    }

    .multi-shipment .track-shipment-card .form-control {
        flex-basis: calc(100% - 104px);
        max-width: calc(100% - 104px);
        padding-left: 26.5px;
        background-size: 20px 20px;
    }

    .multi-shipment .track-shipment-card-text {
        font-size: 12px;
        line-height: 16px;
    }

    .multi-shipment .track-shipment-details .shipment-details-card .shipment-details-col {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .multi-shipment .shipment-login-card {
        column-gap: 12px;
    }

    .multi-shipment .shipment-history-track h1 {
        font-size: 18px;
        line-height: 24px;
    }

    .multi-shipment .shipment-details-info {
        column-gap: 14px;
    }

    .multi-shipment .shipment-card .shipment-stops {
        width: 100%;
        justify-content: space-between;
    }

    .multi-shipment .shipment-card .shipment-stops .shipment-stop-points,
    .multi-shipment .shipment-card .shipment-stops .shipment-stop-country {
        margin-right: 10px;
    }

    .multi-shipment .shipment-card .shipment-progess-container {
        flex-direction: column;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point {
        flex-direction: row;
        align-items: center;
        column-gap: 12px;
        /* padding: 14px 0; */
        padding: 0;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point::before {
        height: 50%;
        width: 3px;
        left: 19.5px;
        top: 0;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point::after {
        height: 50%;
        width: 3px;
        left: 19.5px;
        top: 50%;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start::after,
    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end::before {
        /* height: 80%; */
        height: 70%;
        width: 3px;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start::after {
        left: 19.5px;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .track-check-info {
        flex-direction: row;
        column-gap: 10px;
        margin-top: 0;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .inbetween-info,
    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .dest-info,
    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .orgin-info {
        order: 3;
        margin-left: auto;
        text-align: right;
        align-items: flex-end;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .dest-info,
    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.shipment-track-info .orgin-info {
        max-width: 122px;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start,
    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end {
        max-width: none;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.start {
        padding-top: 0;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point.end {
        padding-bottom: 0;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point span {
        font-size: 12px;
    }

    .multi-shipment .shipment-card .shipment-track-points {
        margin-bottom: 16px;
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed {
        max-width: 184px;
        top: 50%;
        left: 124px;
        transform: translateY(-50%);
    }

    .multi-shipment .shipment-card .shipment-progess-container .shipment-progess-point .tooltip-departed .arrow {
        top: 50%;
        left: -38px;
        transform: translateY(-50%) rotate(90deg) translateX(0);
    }

    .multi-shipment .shipment-card .shipment-info-container .shipment-actions {
        column-gap: 8px;
    }

    .multi-shipment .shipment-card .shipment-info .shipment-num h5 {
        font-size: 14px;
        line-height: 20px;
    }

    .multi-shipment .shipment-attachment-list-item .shipment-attachment-download .title {
        display: none;
    }

    .multi-shipment .shipment-attachment-list-item .shipment-attachment-file .title {
        font-size: 16px;
        line-height: 20px;
    }

    .multi-shipment .shipment-attachment-list-item .shipment-attachment-file .file {
        font-size: 12px;
        line-height: 16px;
    }

    .multi-shipment .shipment-attachment-list-item .shipment-attachment-file {
        row-gap: 2px;
    }


}
.shy-section:first-child {
    padding-top: 2.5%
}

.shy-section:last-child {
    padding-bottom: 10px
}

@media (min-width: 668px) {
    .shy-section:first-child {
        padding-top: 1.25%
    }
}

@media (min-width: 1025px) {
    .shy-section:first-child {
        padding-top: .83333%
    }
}

.shy-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2.5%
}

.shy-container-2048 {
    max-width: 2048px !important;
    padding: 0 !important
}

.shy-container:after {
    content: " ";
    display: block;
    clear: both
}

@media (min-width: 668px) {
    .shy-container {
        padding: 0 1.25%
    }
}

@media (min-width: 1025px) {
    .shy-container {
        padding: 0 .83333%
    }
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-pagination-bullet-active {
    background: #0c3d3c !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 23px !important;
}

.swiper-button-prev,
.swiper-button-next {
    color: #0c3d3c !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    top: 62% !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, -5px) !important;
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.div-search-numbers {
    text-decoration: none;
    font-size: 14px;
    border: 1px solid #eef1f5;
    background-color: #fff;
    color: #6d7278;
    border-radius: 3px;
    height: 35px;
    padding: 5px;
    margin: 5px;
}

.div-search-numbers .label-search-numbers {
    font-size: 14px;
    color: #6d7278;
    height: 20px;
    line-height: 20px;
}

@media (max-width: 568px) {
    .div-search-numbers .btn-search-numbers {
        top: 1px;
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .div-search-numbers .btn-search-numbers {
        top: 1px;
        font-size: 16px;
    }
}

.div-search-numbers .btn-search-numbers {
    color: #6d7278;
    text-decoration: none;
    position: relative;
    top: 0;
    font-size: 18px;
    cursor: pointer;
}

.multi-shipment .shipment-card .shipment-tag {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px 16px;
    box-shadow: 0px 3px 6px #0000001d;
    border-radius: 0px 0px 5px 0px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #0c3d3c;
}

.empty-state {
    width: 750px;
    margin: 40px auto;
    border-radius: 4px;
  }
  
  .empty-state__content {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .empty-state__content .empty-state__icon {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
    background-color: #f7fafc;
    box-shadow: 0px 2px 1px #e1e3ec;
  }
  
  .empty-state__content .empty-state__icon img {
    width: 170px;
  }
  
  .empty-state__content .empty-state__message {
    color: #0c3d3c;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.85rem;
  }
  
  .empty-state__content .empty-state__help {
    color: #a2a5b9;
    font-size: 0.875rem;
  }
  
  
  .credit {
    color: #A2A5B9;
    font-size: .75rem;
    text-align: center;
    
   
  }
  .credit  a {
    color: #444;
  }


  .loader-42 {
	margin-top: 140px;
    width: 8em;
	height: .66em;
	border: .05em currentcolor solid;
	border-radius: .1em;
	background: linear-gradient(-60deg, transparent 0%, transparent 50%, currentcolor 50%, currentcolor 75%, transparent 75%, transparent);
	background-size: 1em 2em;
	background-position: 0 0;
	animation: loader-42 0.8s infinite linear;
}
@keyframes loader-42 {
	from { background-position: 0 0; }
	to { background-position: -2em 0; }
}

.box {
	display: inline-block;
	border-radius: 3px;
	font-size: 30px;
	position: relative;
	margin-bottom: .25em;
	vertical-align: top;
	transition: .3s color, .3s border, .3s transform, .3s opacity;
}

.box:hover {
	color: #fff;
	background-color: rgba(0,0,0,.1);
	font-size: 0;
	padding: 0;
	border-width: 3px;
	line-height: 200px;
	opacity: 1;
	transform: scale(1.2);
	z-index: 2;
}

.box:hover [class*="loader-"] {
	font-size: 70px;
	line-height: 200px;
}

button {
	display: inline-block;
	background: transparent;
	border: 2px solid currentcolor;
	color: white;
	padding: .5em 1em;
	border-radius: 5px;
	font-size: calc(16px + 2vw);
}

